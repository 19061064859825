import LanguageContext from '@lib/contexts/languageContext';
import { Trans } from '@lingui/react';
import React, { useContext } from 'react';
import styled from 'styled-components';
import Icon from '@components/Icon';
import { Box, Flex } from '@components/layout/Grid';
import LoginModal from '@organisms/modals/LoginModal';
import { UserContext } from '@lib/contexts/UserProvider';
import AnalyticsManager from '@lib/analytics/manager';
import useFlags from '@lib/hooks/useFlags';
import { Button } from '@components/ButtonV2';

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.secondary['gray-5']};
  padding-bottom: 56%;
  position: relative;
`;

const AbsoluteCenter = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
`;

const Message = styled.p`
  @media (max-width: ${props => `${props.theme.viewports.mobile}px`}) {
    font-size: 12px;
  }
  font-size: 24px;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  margin: 0;
  padding: 0;
`;

const VideoCoverLocked = () => {
  const [flags] = useFlags();
  const [currentUser] = useContext(UserContext);
  const lang = useContext(LanguageContext);

  const onUpgrade = () => {
    //track
    AnalyticsManager()._PIXEL_TRACK('Upgrade From Video Lock', true);
  };

  const onRegister = () => {
    AnalyticsManager()._PIXEL_TRACK('Register From Video Lock', true);
  };

  const onLogin = () => {
    AnalyticsManager()._PIXEL_TRACK('Login From Video Lock', true);
  };

  let content;
  if (currentUser?.id) {
    let href = `/${lang}/subscription/select`,
      button_text = <Trans id="video_cover_locked.Upgrade" />;

    if (currentUser?.trial && flags?.enable_manual_trial) {
      href = `/${lang}/get-started/trial`;
      button_text = <Trans id="metadata.start_trial_btn" />;
    }

    content = (
      <Flex justifyContent="center">
        <Box mx="5px">
          <Button appearance="white" href={href} onClick={() => onUpgrade()}>
            {button_text}
          </Button>
        </Box>
      </Flex>
    );
  } else {
    content = (
      <Flex justifyContent="center">
        <Box mx="5px">
          <LoginModal>
            {handleOpenModal => (
              <Button
                appearance="white"
                onClick={() => {
                  onLogin();
                  handleOpenModal();
                }}
              >
                <Trans id="video_cover_locked.login" message={`Login`} />
              </Button>
            )}
          </LoginModal>
        </Box>
        <Box mx="5px">
          <Button
            appearance="pink"
            href={`/${lang}/register/account`}
            onClick={onRegister}
            prefetch={false}
          >
            <Trans id="video_cover_locked.register" message={`Try for Free`} />
          </Button>
        </Box>
      </Flex>
    );
  }

  return (
    <Wrapper data-testid="VideoCoverLocked">
      <AbsoluteCenter>
        <Box px={['20px', '20px', '60px']} py={['20px', '20px', '0px']}>
          <Flex justifyContent="center" mb={['10px', '10px', '20px']}>
            <Icon name="ygb-icon-infob" fontSize="30px" color="white" />
          </Flex>
          <Box mb={['10px', '10px', '30px']}>
            <Message>
              <Trans
                id="video.premium_msg"
                message={`You have watched a 60 second preview. Do you want to see the rest?`}
              />
            </Message>
          </Box>
          {content}
        </Box>
      </AbsoluteCenter>
    </Wrapper>
  );
};

export default VideoCoverLocked;

import React from 'react';
import { createRoot } from 'react-dom/client';
import CustomButton from './CustomButton';

export default function CustomButtonFactory(videojs) {
  const vjsComponent = videojs.getComponent('Button');

  class VjsCustomButton extends vjsComponent {
    constructor(player, options) {
      super(player, options);
      this.root = createRoot(this.el());
      /* Bind the current class context to the mount method */
      this.mount = this.mount.bind(this);
      this.handleClick = this.onClickHandler.bind(this);
      this.configOptions = options;
      /* When player is ready, call method to mount React component */
      this.addClass('vjs-control');
      this.addClass('vjs-button');
      switch (this.configOptions.type) {
        case 'next':
          this.addClass('vjs-button-next');
          break;
        case 'prev':
          this.addClass('vjs-button-prev');
          break;
        // case 'cast':
        //   this.addClass('vjs-button-cast');
        //   break;
        default:
          break;
      }
      player.ready(() => {
        this.mount();
      });

      /* Remove React root when component is destroyed */
      this.on('dispose', () => {
        this.root.unmount();
      });
    }
    onClickHandler = event => {
      switch (this.configOptions.type) {
        case 'next':
          this.configOptions.next();
          break;
        case 'prev':
          this.configOptions.prev();
          break;
        // case 'cast':
        //   this.configOptions.cast();
        //   break;
        default:
          break;
      }
    };

    buttonText = () => {
      switch (this.configOptions.type) {
        case 'next':
          return null;
        case 'prev':
          return null;
        // case 'cast':
        //   return 'cast';
        default:
          break;
      }
    };

    iconClassName = () => {
      switch (this.configOptions.type) {
        case 'next':
          return 'ygb-icon-next2';
        case 'prev':
          return 'ygb-icon-previous2';
        // case 'cast':
        //   return 'cast-button';
        default:
          break;
      }
    };
    /**
     * We will render out the React EpisodeList component into the DOM element
     * generated automatically by the VideoJS createEl() method.
     *
     * We fetch that generated element using `this.el()`, a method provided by the
     * vjsComponent class that this class is extending.
     */
    mount() {
      this.root.render(
        <CustomButton
          vjsComponent={this}
          body={this.buttonText()}
          classIconName={this.iconClassName()}
          onClickHandler={this.onClickHandler}
        />
      );
    }
  }

  //

  if (!videojs.getPlugin('vjsCustomButton')) {
    videojs.registerComponent('vjsCustomButton', VjsCustomButton);
  }
}

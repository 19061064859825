import { useCallback, useEffect, useRef } from 'react';
import useFlags from './useFlags';
import {
  useVideoWatchCompletedMutation,
  useVideoWatchMutation,
} from '@gql/generated';
import { once } from 'lodash';

type WatchLog = Array<{
  segmentIndex: number;
  videoId: string;
  timestamp: number;
}>;

const VIDEO_FRAGMENTS = 5;
export const useVideoStats = ({
  videoId,
  // duration in minutes
  duration,
}) => {
  const currentTimeRef = useRef<number>(null);
  // in seconds
  const SEGMENT_DURATION = (duration * 60) / VIDEO_FRAGMENTS;
  const [flags] = useFlags();
  const _log = useRef<WatchLog>([]);
  const [sendVideoWatch] = useVideoWatchMutation();
  const [sendVideoCompleted] = useVideoWatchCompletedMutation();

  useEffect(() => {
    const _videoId = videoId;
    return () => {
      const time = currentTimeRef.current;
      if (time > 0 && _videoId) {
        sendVideoWatch({
          variables: {
            videoId: _videoId,
            seconds: Math.floor(time % VIDEO_FRAGMENTS),
            cursorAt: Math.floor(time),
          },
          update(cache) {
            cache.modify({
              id: `Video:${_videoId}`,
              broadcast: true,
              fields: {
                cursorAt: () => {
                  return Math.floor(time);
                },
              },
            });
          },
        });
        _log.current = [];
        currentTimeRef.current = null;
      }
    };
  }, []);

  const playStartedEvent = useCallback(
    once(() => {
      sendVideoWatch({
        variables: {
          videoId,
          seconds: 0,
          cursorAt: 0,
        },
      });
    }),
    [videoId]
  );

  const videoProgress = useCallback(
    currentTime => {
      if (!flags.video_stats) {
        return null;
      }

      // send once anytime to mark video started
      playStartedEvent();

      // start at 1
      const segmentIndex = Math.floor(currentTime / SEGMENT_DURATION) + 1;

      // if user resets cursor we re-send event, so we clean up
      _log.current = _log.current.filter(item => {
        // user probably reset player
        if (item.timestamp > currentTime) {
          return false;
        }
        return true;
      });

      const segmentEventSent = _log.current.some(el => {
        if (el.segmentIndex === segmentIndex) {
          return true;
        }
        return false;
      });

      if (
        // didn't send an event already
        !segmentEventSent &&
        // must have completed at least 90% of the current
        currentTime % SEGMENT_DURATION > SEGMENT_DURATION * 0.9
      ) {
        _log.current.push({
          videoId,
          segmentIndex: segmentIndex,
          timestamp: currentTime,
        });
        if (segmentIndex === 4) {
          sendVideoCompleted({
            variables: {
              videoId,
              cursorAt: Math.floor(currentTime),
              seconds: Math.floor(SEGMENT_DURATION),
            },
          });
        } else {
          sendVideoWatch({
            variables: {
              videoId,
              seconds: Math.floor(SEGMENT_DURATION),
              cursorAt: Math.floor(currentTime),
            },
          });
        }
      }
    },
    [flags]
  );

  return videoProgress;
};

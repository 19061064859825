import { loadScriptAsync } from './utils';

const SENDER_SDK_URL =
  '//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1';

let _promise;
class CastLoader {
  static load(): Promise<any> {
    return new Promise(resolve => {
      CastLoader.loadCastSDK().catch(e => {
        console.warn('Cast sender lib loading failed', e.message);
      });
      window['__onGCastApiAvailable'] = (isAvailable: boolean) => {
        CastLoader.onGCastApiAvailable(isAvailable, resolve);
      };
    });
  }

  static loadCastSDK(): Promise<any> {
    if ((window['cast'] && window['cast']['framework']) || _promise) {
      return Promise.resolve();
    }
    _promise = loadScriptAsync(SENDER_SDK_URL);
    return _promise;
  }

  static onGCastApiAvailable(
    isAvailable: boolean,
    resolve: (...params: any) => void
  ): void {
    if (isAvailable) {
      resolve();
    } else {
      console.warn(`Google cast API isn't available yet`);
    }
  }
}

export { CastLoader };

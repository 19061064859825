import React from 'react';
import { createRoot } from 'react-dom/client';
import styled, { ThemeProvider } from 'styled-components';
import theme from '@lib/theme';

const IconWrapper = styled.span`
  cursor: pointer;
  max-height: 40px;
  svg {
    max-height: 40px;
    width: 100%;
    padding: 7px;
    display: block;
    svg {
      max-height: 40px;
    }
  }
`;

export default function AirplayFactory(videojs) {
  const vjsComponent = videojs.getComponent('Button');
  class VjsAirplayButton extends vjsComponent {
    constructor(player, options) {
      super(player, options);
      this.root = createRoot(this.el());
      /* Bind the current class context to the mount method */
      this.mount = this.mount.bind(this);
      this.configOptions = options;
      /* When player is ready, call method to mount React component */
      this.addClass('vjs-control');
      this.addClass('vjs-button');
      this.addClass('vjs-button-airplay');
      player.ready(() => {
        this.mount();
      });

      /* Remove React root when component is destroyed */
      this.on('dispose', () => {
        this.root.unmount();
      });
    }

    buttonText = () => {
      return null;
    };

    iconClassName = () => {
      return 'ygb-icon-cast';
    };

    airplay() {
      this?.player_?.children_?.[0]?.webkitShowPlaybackTargetPicker?.();
    }

    castStateClass = () => {};
    /**
     * We will render out the React EpisodeList component into the DOM element
     * generated automatically by the VideoJS createEl() method.
     *
     * We fetch that generated element using `this.el()`, a method provided by the
     * vjsComponent class that this class is extending.
     */
    mount() {
      this.root.render(
        <ThemeProvider theme={theme}>
          <IconWrapper onClick={() => this.airplay()} onTouchStart={this.cast}>
            <svg
              fill="#fff"
              version="1.1"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M6,22H18L12,16M21,3H3A2,2 0 0,0 1,5V17A2,2 0 0,0 3,19H7V17H3V5H21V17H17V19H21A2,2 0 0,0 23,17V5A2,2 0 0,0 21,3Z" />
            </svg>
          </IconWrapper>
        </ThemeProvider>
      );
    }
  }

  videojs.registerComponent('VjsAirplayButton', VjsAirplayButton);
}
